.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .wrapper .logo {
    position: relative;
    overflow: hidden;
    text-indent: -9999px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    white-space: nowrap;
    margin-right: 9px; }
    .wrapper .logo:last-child {
      margin-right: 0; }
  .wrapper [class*='_logo-apple']::after {
    content: '';
    width: 36px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
            background-size: contain;
    left: 0;
    width: 51px;
    background-image: url("./../../../img/logo-apple.svg"); }
  .wrapper [class*='_logo-dummy']::after {
    content: '';
    width: 36px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
            background-size: contain;
    left: 0;
    width: 51px;
    background-image: url("./../../../img/logo-dummy.svg"); }
  .wrapper [class*='_logo-klarna-checkout']::after {
    content: '';
    width: 36px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
            background-size: contain;
    left: 0;
    background-image: url("./../../../img/logo-klarna-checkout.svg"); }
  .wrapper [class*='_logo-paypal']::after {
    content: '';
    width: 36px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
            background-size: contain;
    left: 0;
    background-image: url("./../../../img/logo-paypal.svg"); }
  .wrapper [class*='_logo-dhl']::after {
    content: '';
    width: 36px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
            background-size: contain;
    left: 0;
    background-image: url("./../../../img/logo-dhl.svg"); }
  .wrapper [class*='_logo-adyen']::after {
    content: '';
    width: 36px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
            background-size: contain;
    left: 0;
    background-image: url("./../../../img/logo-adyen.svg"); }
  .wrapper [class*='_logo-stripe-checkout']::after {
    content: '';
    width: 36px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
            background-size: contain;
    left: 0;
    background-image: url("./../../../img/logo-stripe-checkout.svg"); }
