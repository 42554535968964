/* Colors */
/* Typography */
/* Spacing */
.inputBox {
  opacity: 1;
  position: relative;
  display: table;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 12px 0; }
  .inputBox::before {
    content: '';
    display: table; }

.labelElements {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: left; }

.labelText {
  line-height: 1.4;
  position: relative; }

.errorWrapper {
  padding-left: 32px; }

.noPaddingLeft {
  padding-left: 0; }

.labelInner {
  margin-left: 12px;
  font-size: 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .labelInner .note {
    float: right;
    text-align: right; }

.reverse {
  width: 100%; }
  .reverse .label {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .reverse .labelInner {
    margin-left: 0; }

.checkbox {
  padding: 0; }
  .checkbox .label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .checkbox .input {
    position: relative;
    height: 20px;
    width: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #b6b6b6;
    -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
    transition: -webkit-box-shadow 0.2s ease-in-out;
    -o-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    margin: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 3px; }
    .checkbox .input.error {
      border-color: #f45b4f; }
  .checkbox .input::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
         -o-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out 0.1s;
    -o-transition: all 0.2s ease-in-out 0.1s;
    transition: all 0.2s ease-in-out 0.1s; }
  .checkbox .input:checked {
    -webkit-box-shadow: 0 0 0 10px #0027f4 inset;
            box-shadow: 0 0 0 10px #0027f4 inset;
    border-color: #0027f4;
    outline: none; }
  .checkbox .input:checked::after {
    position: absolute;
    background-image: url(../../../img/tick.svg);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  .checkbox.toggle {
    width: 100%;
    padding: 0; }
    .checkbox.toggle .label {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; }
    .checkbox.toggle .labelInner {
      margin-right: 12px;
      margin-left: 0; }
    .checkbox.toggle .inputToggle {
      width: 40px;
      min-width: 40px;
      border: 1px solid #999999;
      border-radius: 10px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      background: transparent; }
    .checkbox.toggle .inputToggle::after {
      width: 16px;
      height: 16px;
      background: #525252;
      top: 1px;
      left: 1px;
      border-radius: 50%;
      margin: 0;
      -webkit-transform: none;
           -o-transform: none;
              transform: none;
      opacity: 1; }
    .checkbox.toggle .inputToggle:checked {
      border: 1px solid #0027f4;
      background: #0027f4;
      outline: none; }
    .checkbox.toggle .inputToggle:checked::after {
      left: calc(100% - 17px);
      background: #fff; }

.radio {
  width: 100%;
  padding: 12px; }
  .radio label.label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%; }
  .radio span.labelInner {
    width: 100%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .radio:first-child {
    border-radius: 4px 4px 0 0; }
  .radio:last-child {
    border-radius: 0 0 4px 4px; }
  .radio input.input {
    border-radius: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    position: relative;
    cursor: pointer;
    vertical-align: -4px;
    border: 1px solid;
    border-color: #b6b6b6;
    margin: 0;
    -webkit-appearance: none;
    -webkit-font-smoothing: inherit;
    background: transparent;
    line-height: normal;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
    .radio input.input::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: scale(0.2);
           -o-transform: scale(0.2);
              transform: scale(0.2);
      -webkit-transition: all 0.2s ease-in-out 0.1s;
      -o-transition: all 0.2s ease-in-out 0.1s;
      transition: all 0.2s ease-in-out 0.1s;
      opacity: 0;
      width: 4px;
      height: 4px;
      margin-left: -2px;
      margin-top: -2px;
      background-color: #fff;
      border-radius: 50%; }
    .radio input.input.checked {
      border: none;
      outline: none;
      -webkit-box-shadow: 0 0 0 10px #0027f4 inset;
              box-shadow: 0 0 0 10px #0027f4 inset; }
      .radio input.input.checked::after {
        -webkit-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
        opacity: 1; }
  .radio[class*='_logo-paypal'] .labelText {
    color: transparent;
    height: 15px; }
    .radio[class*='_logo-paypal'] .labelText::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
           -o-transform: translateX(-50%);
              transform: translateX(-50%);
      background-position: center center;
      -webkit-background-size: contain;
              background-size: contain;
      background-image: url("./../../../img/logo-paypal.svg"); }
  .radio[class*='_logo-dhl'] .labelText {
    color: transparent;
    height: 15px; }
    .radio[class*='_logo-dhl'] .labelText::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
           -o-transform: translateX(-50%);
              transform: translateX(-50%);
      background-position: center center;
      -webkit-background-size: contain;
              background-size: contain;
      background-image: url("./../../../img/logo-dhl.svg"); }
  .radio[class*='_logo-klarna-checkout'] .labelText {
    color: transparent;
    height: 15px; }
    .radio[class*='_logo-klarna-checkout'] .labelText::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
           -o-transform: translateX(-50%);
              transform: translateX(-50%);
      background-position: center center;
      -webkit-background-size: contain;
              background-size: contain;
      background-image: url("./../../../img/logo-klarna-checkout.svg"); }
  .radio[class*='_logo-adyen'] .labelText {
    color: transparent;
    height: 15px; }
    .radio[class*='_logo-adyen'] .labelText::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
           -o-transform: translateX(-50%);
              transform: translateX(-50%);
      background-position: center center;
      -webkit-background-size: contain;
              background-size: contain;
      background-image: url("./../../../img/logo-adyen.svg"); }
  .radio[class*='_logo-stripe-checkout'] .labelText {
    color: transparent;
    height: 15px; }
    .radio[class*='_logo-stripe-checkout'] .labelText::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
           -o-transform: translateX(-50%);
              transform: translateX(-50%);
      background-position: center center;
      -webkit-background-size: contain;
              background-size: contain;
      background-image: url("./../../../img/logo-stripe-checkout.svg"); }
  .radio[class*='_logo-apple'] .labelText {
    color: transparent;
    height: 15px; }
    .radio[class*='_logo-apple'] .labelText::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
           -o-transform: translateX(-50%);
              transform: translateX(-50%);
      background-position: center center;
      -webkit-background-size: contain;
              background-size: contain;
      background-image: url("./../../../img/logo-apple.svg"); }
  .radio[class*='_logo-dummy'] .labelText {
    color: transparent;
    height: 15px; }
    .radio[class*='_logo-dummy'] .labelText::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
           -o-transform: translateX(-50%);
              transform: translateX(-50%);
      background-position: center center;
      -webkit-background-size: contain;
              background-size: contain;
      background-image: url("./../../../img/logo-dummy.svg"); }
  .radio[class*='_logo-dummy'][class*='country-restricted-law-info-SE'] .labelText::after {
    background-image: url("./../../../img/logo-dummy-SE.svg"); }
  .radio.column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border: none;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    min-width: 136px;
    width: auto;
    padding: 0;
    min-height: 84px; }
    @media only screen and (min-width: 375px) {
      .radio.column {
        min-width: 150px; } }
    @media only screen and (min-width: 425px) {
      .radio.column {
        min-width: 175px; } }
    @media only screen and (min-width: 801px) {
      .radio.column {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
        min-width: 25%;
        /* &:last-child,
      &:first-child {
        flex: 0 0 auto;
      } */ } }
    .radio.column .input {
      width: 16px;
      height: 16px; }
    .radio.column .label {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      margin: 6px;
      padding: 12px; }
      .radio.column .label:hover {
        cursor: pointer;
        border-color: #b6b6b6; }
      .radio.column .label.selected {
        border-color: #0027f4; }
    .radio.column .labelElements {
      text-align: center;
      width: 100%; }
      .radio.column .labelElements .labelText {
        font-size: 13px; }
    .radio.column .labelInner {
      margin-left: 0;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-top: 8px;
      position: initial;
      height: auto;
      width: 100%; }
      .radio.column .labelInner .note {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 10px; }

.big label.label .labelInner {
  font-size: 16px; }

.klarnaTerms {
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
  color: #666666; }
  .klarnaTerms a {
    color: #0027f4;
    text-decoration: underline; }
