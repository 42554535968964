/* Colors */
/* Typography */
/* Spacing */
.formContent {
  position: relative; }

.price {
  padding: 4px 0;
  width: 100%;
  text-align: center;
  font-size: 13px; }

.buttonWrapper {
  padding-bottom: 100px;
  margin-top: 32px; }

.readOnly {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  pointer-events: none;
  opacity: 0.6; }

.klarnaWarning {
  margin: 25px 0 12px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 15px; }

.klarnaWarningContainer {
  text-decoration: none;
  color: #000; }

.klarnaWarningIcon {
  background-image: url("./../../../img/warning_sign.svg");
  -webkit-background-size: contain;
          background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50px;
      -ms-flex: 0 0 50px;
          flex: 0 0 50px; }

.klarnaLink {
  text-decoration: underline; }
